<template>
  <c-flex
    width="100%"
    flex-direction="column"
  >
    <c-box
      :background-color="style.color"
      align-self="flex-start"
      padding="1px 10px"
      border-top-left-radius="8px"
      border-top-right-radius="8px"
      :font-size="['14px', '16px']"
    >
      <c-text color="neutral.white">
        {{ label }}
      </c-text>
    </c-box>
    <c-box
      margin-top="4px"
      :background-color="style.softColor"
      border="2px solid"
      :border-color="style.color"
      padding="8px"
      border-radius="8px"
    >
      <c-box
        :style="`--color: ${style.colorHex};`"
        class="items"
      >
        <slot
          name="items"
        >
          <div
            v-if="typeof items === 'string'"
            class="ck-content"
            v-html="items"
          />
          <c-box
            v-else
            as="ul"
            padding-left="27px"
          >
            <c-box
              v-for="(item, index) in items"
              :key="index"
              as="li"
              :color="style.color"
              :font-size="['14px', '16px']"
            >
              <c-text
                :color="style.color"
                font-weight="500"
              >
                {{ item }}
              </c-text>
            </c-box>
          </c-box>
        </slot>
      </c-box>
    </c-box>
  </c-flex>
</template>

<script>
import { CBox, CFlex, CText } from '@chakra-ui/vue'

export default {
  name: 'CardInfoThingsThatNeed',
  components: {
    CBox,
    CFlex,
    CText,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    items: {
      type: [Array, String],
      default: () => [],
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    style() {
      switch (this.color) {
        case 'warning':
          return {
            color: 'warning.400',
            softColor: 'warning.50',
            colorHex: '#DA6D06',
          }
        case 'danger':
          return {
            color: 'danger.400',
            softColor: 'danger.50',
            colorHex: '#D32737',
          }
        default:
          return {
            color: 'primary.400',
            softColor: 'primary.50',
            colorHex: '#008C81',
          }
      }
    },
  },
}
</script>

<style scoped>
::v-deep .items * {
  color: var(--color) !important;
}

::v-deep ul, ::v-deep ol {
  padding-left: 20px
}
</style>