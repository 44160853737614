import { isDataEmpty } from '@/utils/is-data-empty'

const getUniversityName = (id, listUniversities)=>{
  if (isDataEmpty(listUniversities)) return ''
  let a = listUniversities?.find((dt) => dt.id.toString() === id)
  return a?.name || ''
}

export const educationsParser = (educations = [], listUniversities = []) => {
  return educations?.map((education) => {
    const educationLevel = education.level ? education.level.toUpperCase() + ' - ' : ''
    if (education.university === '0') {
      return `${educationLevel} ${education.otherUniversity}`.trim()
    }
    return `${educationLevel} ${getUniversityName(education.university, listUniversities)}`.trim()
  })
}

export const getFullnameAndTitle = (name = '-', educations = []) => {
  const title = educations?.map((v) => v.title)
  return `${name} ${title ? title.join(', ') : ''}`.trim()
}
